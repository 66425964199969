import React from 'react';
import Contact from '../components/contact'
import { useTranslation } from 'react-i18next';

function Careers() {
  const { t } = useTranslation();

  return (
    <div className='careers'>
      <div className="header-page">
        {t("Interested In Working With Us?")}
      </div>
      <div className="content-info bg-green">
        <div className="container">
          <div className="sub-title">
            {t("At Altedore, we are constantly on the lookout for forward-thinking individuals who are passionate about shaping a sustainable future. While we may not have specific openings at the moment, we believe in the power of connecting with those who share our vision of using AI as a catalyst for positive environmental change.")}
          </div>
          <div className="sub-title mt8">
          {t("If you are driven by the sustainability agenda and see AI as a pivotal tool in this quest, we would love to hear from you. Join us in our mission to innovate for a better, greener world - your expertise could be the key to unlocking new possibilities in sustainable practices and carbon accounting.")}
          </div>
          <div className="sub-title mt8">
          {t("Reach out to explore how your skills and passions can flourish at Altedore.")}
          </div>
        </div>
      </div>
      <Contact />

    </div>
  );
}

export default Careers;

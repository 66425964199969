import React from 'react';
import Contact from '../components/contact'
import { useTranslation } from 'react-i18next';

function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <div className='privacy-policy'>
      <div className="header-page">
        {t("Privacy Policy")}
      </div>
      <div className="content">
        <div className="container">
          <b>{t("Last Updated: 24 Feb 2024")}</b>
          <br />
          <div className='mt12'>
            <b>{t("Introduction:")}</b>
            <p>{t("Welcome to Altedore. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This policy outlines how we collect, use, disclose, and safeguard your data when you visit our website.")}</p>
          </div>

          <div className='mt12'>
            <b>{t("Information Collection:")}</b>
            <p>{t("We collect information that you provide to us directly, such as when you fill out a contact form, subscribe to a newsletter, or apply for a job. This information may include your name, email address, phone number, and any other details you choose to provide.")}</p>
          </div>

          <div className='mt12'>
            <b>{t("Use of Information:")}</b>
            <p>{t("The information we collect is used to provide, maintain, and improve our services, respond to your inquiries, process your requests, and for internal analytics purposes. We do not share or sell your personal information to third parties for their marketing purposes.")}</p>
          </div>


          <div className="mt12">
            <b>{t("Cookies and Tracking Technologies:")}</b>
            <p>{t("We use cookies and similar tracking technologies to track the activity on our website and hold certain information. These are used to enhance your experience while using our website by remembering your preferences and settings.")}</p>
          </div>

          <div className="mt12">
            <b>{t("Data Security:")}</b>
            <p>{t("We take the security of your data seriously and implement appropriate measures to protect it from unauthorized access, alteration, disclosure, or destruction.")}</p>
          </div>


          <div className="mt12">
            <b>{t("Your Data Protection Rights:")}</b>
            <p>{t("Depending on your location, you may have certain rights regarding your personal data, including the right to access, correct, or delete the information we hold about you.")}</p>
          </div>


          <div className="mt12">
            <b>{t("Changes to This Privacy Policy:")}</b>
            <p>{t("We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.")}</p>
          </div>

          <div className="mt12">
            <b>{t("Contact Us:")}</b>
            <p>{t("If you have any questions about this Privacy Policy, please contact us at  contact@altedore.com, or use the form below.")}</p>
          </div>
        </div>
      </div>
      <Contact />

    </div>
  );
}

export default PrivacyPolicy;

import React from 'react';
import Contact from '../components/contact'
import { useTranslation } from 'react-i18next';

function Carbon() {
  const { t } = useTranslation();

  return (
    <div className='carbon'>
      <div className="header-page">
        {t('Understanding ESG: Pillars, Principles, and Standards')}
      </div>
      <div className="bg-text" style={{ backgroundImage: `url(${require('../assets/nature.png')})` }}>
        <div className="container">
          <div className="full-width bg-gray text-primary move-top">
            <div className="title mb4">
              {t('What Does ESG Stand For?')}
            </div>
            <div className="sub-title">
              {t("Environmental, Social, and Governance (ESG) are criteria that offer a framework for assessing a company's impact on the world and its operational integrity. ESG factors are increasingly important in determining a company's long-term success and sustainability.")}
            </div>
            <div className="sub-title mt8">
              <b>
                {t('The Three Pillars of ESG: ')}
              </b>
            </div>
            <ul className='num-list'>
              <li>
                {t('Environmental: This pillar considers how a company performs as a steward of nature. It includes energy use, waste management, pollution, natural resource conservation, and treatment of animals.')}
              </li>
              <li>
                {t("Social: This focuses on a company's relationships and its reputation within its community. It encompasses employee relations and diversity, working conditions, local communities, health and safety, and conflict management.")}
              </li>
              <li>
                {t("Governance: Governance deals with a company's leadership, executive pay, audits, internal controls, and shareholder rights.")}
              </li>
            </ul>
          </div>
          <div className="right bg-primary-88 text-white mt12">
            <div className="title mb1">
              {t('What are some examples of ESG Practices?')}
            </div>
            <div className="sub-title pt6">
              {t('Environmental: Reducing carbon footprints, improving energy efficiency, investing in renewable energy.')}
            </div>
            <div className="sub-title pt6">
              {t('Social: Ensuring fair labor practices, supporting employee wellbeing, engaging in fair trade.')}
            </div>
            <div className="sub-title pt6">
              {t('Governance: Implementing transparent accounting methods, ensuring board diversity, upholding shareholder rights.')}
            </div>
          </div>
          <div className="left sub-title bg-green text-white mt12">
            <div className="title mb7">
              {t('What is the purpose of ESG?')}
            </div>
            <div className="sub-title">
              {t("The overarching goal of ESG is to help ensure that the companies' operations are sustainable, ethical, and responsible in the long term, benefiting all stakeholders including employees, shareholders, and the society at large.")}
            </div>
          </div>
          <div className="right bg-gray-70 text-primary mt12">
            <div className="title mb1">
              {t('What are some examples of ESG Reporting Standards:')}
            </div>
            <div className="sub-title mt6">
              {t('CSRD (Corporate Sustainability Reporting Directive): A European Union directive focusing on sustainability reporting.')}
            </div>
            <div className="sub-title mt6">
              {t('SEC Standards: For U.S. companies, focusing on ESG disclosures as part of their reporting to the Securities and Exchange Commission.')}
            </div>
            <div className="sub-title mt6">
              {t('ISSB Standards: The International Sustainability Standards Board, focusing on global sustainability reporting standards.')}
            </div>
          </div>
          <br /><br /><br /><br /><br /><br />
        </div>
      </div>
      <div className="bg-text mt0" style={{ backgroundImage: `url(${require('../assets/nature-2.png')})` }}>
        <div className="container">
          <div className="full-width bg-primary-88 text-white move-top">
            <div className="title mb4">
              {t('How Altedore Can Elevate Your ESG Journey')}
            </div>
            <div className="sub-title">
              {t('At Altedore, we understand that navigating the complexities of ESG reporting and compliance can be a daunting task for businesses. Our unique approach combines deep industry expertise, specialized consulting, and advanced generative AI tooling to support you every step of the way - from inception to full reporting.')}
            </div>
          </div>
          <div className="left sub-title bg-green text-white mt12">
            <div className="title mb7">
              {t('Industry Expertise and Consulting:')}
            </div>
            <div className="sub-title">
              {t("Our team is composed of seasoned professionals with extensive experience in ESG, sustainability, and corporate governance. This expertise allows us to offer tailored consulting services that align with your company's specific needs and goals. Whether you're just beginning your ESG journey or looking to enhance your existing practices, our consultants are equipped to provide strategic guidance, actionable insights, and best practices in sustainability.")}
            </div>
            <div className="sub-title mt8">
              {t('Contact Us for more information.')}
            </div>
          </div>
          <div className="right bg-gray-70 text-primary mt12">
            <div className="title mb1">
              {t('Advanced AI Tooling for ESG Reporting:')}
            </div>
            <div className="sub-title mt6">
              {t('Leveraging the power of generative AI, Altedore introduces a new era of ESG reporting. Our AI-driven tools are designed to simplify and streamline the ESG reporting process, making it more efficient and less resource-intensive. From conducting comprehensive materiality assessments to compiling detailed reports, our technology ensures accuracy and consistency, allowing you to focus on implementing sustainable practices.')}
            </div>
            <div className="sub-title mt6">
              {t('Read More.')}
            </div>
          </div>
        </div>
      </div>
      <div className="content-info">
        <div className="container">
          <div className="title mb6">
            {t('Altedore Provides Comprehensive Support Throughout Your ESG Journey:')}
          </div>
          <ul>
            <li>
              {t('Inception and Strategy Development: We assist in establishing a clear ESG strategy, identifying key focus areas, and setting achievable goals based on your business model and industry standards.')}
            </li>
            <li>
              {t('Materiality Assessments: Our AI tools help identify and prioritize the ESG factors most relevant to your stakeholders and business success, ensuring your reporting is focused and impactful.')}
            </li>
            <li>
              {t('Data Collection and Analysis: We streamline the gathering and analysis of ESG data, utilizing AI to process large datasets efficiently and derive meaningful insights.')}
            </li>
            <li>
              {t("Full ESG Reporting: From drafting initial reports to final submission, our tools and expertise ensure your ESG reporting is comprehensive, compliant with international standards, and reflective of your company's sustainability efforts.")}
            </li>
          </ul>
          <div className="sub-title my4">
            {t('We are not just a service provider; we are your partner in building a sustainable future. By combining human expertise with advanced AI, we empower your business to lead in corporate responsibility and make a tangible impact on the world.')}
          </div>
          <div className="title">
            {t('Get in touch to see how we can help you achieve your ESG goals.')}
          </div>
        </div>
      </div>
      <Contact />

    </div>
  );
}

export default Carbon;

import React from 'react';
import Contact from '../components/contact'
import { useTranslation } from 'react-i18next';

function ContactUs() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="header-page">
        {t("Ready To Embark on Your Sustainability Journey?")}
        <div>{t("Contact Us Today")}</div>
      </div>
      <Contact />

    </div>
  );
}

export default ContactUs;

import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

function Contact() {
  const location = useLocation();
  const currentRoute = location.pathname;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    company: '',
    phone: '',
    email: '',
    message: '',
    file: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevFormData) => ({ ...prevFormData, file }));
  };

  const handleSubmit = async (e) => {
    const api = 'https://contact-mn3joglzla-uc.a.run.app/'
    e.preventDefault();
    setLoading(true);
    console.log(formData)
    if (currentRoute === '/careers') {
      const file = new FormData();
      file.append('file', formData.file);
      await axios.post(`${api}file`, file).then(async (res) => {
        await axios.post(api, JSON.stringify(formData), {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((res) => {
          console.log(res)
          setMessage('Thanks for your message')
          setLoading(false);
        }).catch(() => {
          setMessage('There was an error, try again')
        })
      }).catch(() => {
        setMessage('There was an error, try again')
      })
    } else {
      await axios.post(api, JSON.stringify(formData), {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        console.log(res)
        setMessage('Thanks for your message')
        setLoading(false);
      }).catch(() => {
        setMessage('There was an error, try again')
      })
    }

  };
  return (
    <div className="contact">
      <div className="container">
        <div className="title">{t("Contact Us")}</div>
        <div className="desc">{t("Stay updated with the latest from Altedore, or find out how we can support your Net Zero journey.")}</div>
        {message ? <div className="message">{message}</div> : <form onSubmit={handleSubmit}>
          <div className="row mb8">
            <div className="col-md-3 col-12">
              <div className="label">{t('First Name')}</div>
            </div>
            <div className="col-md-9 col-12">
              <input required onChange={handleChange} className='my-input' name="firstname" type="text" placeholder='First Name' />
            </div>
          </div>
          <div className="row mb8">
            <div className="col-md-3 col-12">
              <div className="label">{t('Last Name')}</div>
            </div>
            <div className="col-md-9 col-12">
              <input required onChange={handleChange} className='my-input' name="lastname" type="text" placeholder='Last Name' />
            </div>
          </div>

          <div className="row mb8">
            <div className="col-md-3 col-12">
              <div className="label">{t('Company Name')}</div>
            </div>
            <div className="col-md-9 col-12">
              <input required onChange={handleChange} className='my-input' name="company" type="text" placeholder='Company Name' />
            </div>
          </div>

          <div className="row mb8">
            <div className="col-md-3 col-12">
              <div className="label">{t('Phone Number')}</div>
            </div>
            <div className="col-md-9 col-12">
              <input required onChange={handleChange} className='my-input' name="phone" type="text" placeholder='Phone Number' />
            </div>
          </div>

          <div className="row mb8">
            <div className="col-md-3 col-12">
              <div className="label">{t('Email Address')}</div>
            </div>
            <div className="col-md-9 col-12">
              <input required onChange={handleChange} className='my-input' name="email" type="email" placeholder='Email Address' />
            </div>
          </div>

          <div className="row mb8">
            <div className="col-md-3 col-12">
              <div className="label">{t('Message')}</div>
            </div>
            <div className="col-md-9 col-12">
              <textarea required onChange={handleChange} className='my-input' name="message" type="text" placeholder='Message' rows={8}></textarea>
            </div>
          </div>

          {currentRoute === '/careers' && <div className="row mb8">
            <div className="col-md-3 col-12">
              <div className="label">{t('Attach your CV')}</div>
            </div>
            <div className="col-md-9 col-12">
              <input
                type="file"
                name="file"
                onChange={handleFileChange}
                accept=".pdf"
                required
              />
            </div>
          </div>}

          <div className='check'>
            <label className="checkbox-label">
              <input
                type="checkbox"
                required
              />
              <span className="custom-checkbox"></span>{t('I agree to the terms & conditions*')}
            </label>
          </div>
          <div className='check'>
            <label className="checkbox-label">
              <input
                type="checkbox"
                required
              />
              <span className="custom-checkbox"></span>{t('I agree to receive Other Communication from Altedore. For more information please review our privacy policy')}
            </label>
          </div>
          <button className='button mt4' type="submit" disabled={loading}>
            {loading ? 'Submitting...' : 'Submit'}
          </button>
        </form>}
      </div>
    </div>
  );
}

export default Contact;

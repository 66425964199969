import React from 'react';
import Contact from '../components/contact'
import bulb from '../assets/bulb.png'
import laurel from '../assets/laurel.png'
import book from '../assets/book.png'
import cooperation from '../assets/cooperation.png'
import global from '../assets/global.png'
import { useTranslation } from 'react-i18next';

function About() {
  const { t } = useTranslation();

  return (
    <div className='about'>
      <div className="header-page">
        {t('About Us')}
      </div>
      <div className="content-info">
        <div className="container">
          <div className="sub-title">
            {t('Altedore is at the forefront of integrating advanced generative AI with comprehensive carbon accounting, streamlining processes for businesses globally. Our specialised software solutions and consulting services are tailored to meet diverse regulatory requirements, ensuring a seamless and efficient reporting experience.')}
          </div>
          <div className="sub-title mt8">
            {t('Our commitment at Altedore is to guide businesses towards achieving Net Zero targets through strategies underpinned by the latest scientific research and actionable insights. By simplifying the burden of climate compliance reporting, we enable companies to focus on implementing genuine, impactful sustainability practices, driving progress towards Net Zero.')}
          </div>
          <div className="sub-title mt8">
            {t('As a leader in combining technology with carbon accounting, Altedore is dedicated to delivering innovative, AI-powered tools and expert insights. We are not just easing the climate compliance reporting process; we are providing a pathway for businesses to contribute meaningfully to a sustainable and equitable future, ensuring their growth is aligned with global sustainability goals.')}
          </div>
        </div>

      </div>
      <div className="our-founding-principles">
        <div className="container">
          <div className="my-title">
            {t('Our Founding Principles')}
          </div>
          <div className="item">
            <img src={bulb} alt="bulb" />
            <div className="content">
              <div className="item-title">
                {t('Innovative Excellence:')}
              </div>
              <div className="desc">
                {t('Committing to continuous advancement in technology, particularly in generative AI, to lead the way in efficient and effective carbon accounting solutions.')}
              </div>
            </div>
          </div>
          <div className="item">
            <img src={laurel} alt="laurel" />
            <div className="content">
              <div className="item-title">
                {t('Sustainability Leadership:')}
              </div>
              <div className="desc">
                {t('Driving the agenda forward for sustainable business practices, focusing on helping companies achieve Net Zero targets through science-based strategies.')}
              </div>
            </div>
          </div>
          <div className="item">
            <img src={book} alt="book" />
            <div className="content">
              <div className="item-title">
                {t('Integrity and Transparency:')}
              </div>
              <div className="desc">
                {t('Upholding the highest standards of honesty and clarity, ensuring that our clients can trust the accuracy and reliability of our services and reporting tools.')}
              </div>
            </div>
          </div>
          <div className="item">
            <img src={cooperation} alt="cooperation" />
            <div className="content">
              <div className="item-title">
                {t('Client-Centric Approach:')}
              </div>
              <div className="desc">
                {t('Tailoring solutions to meet the unique needs of each client, understanding that each business has its own path to sustainability and climate compliance.')}
              </div>
            </div>
          </div>
          <div className="item">
            <img src={global} alt="global" />
            <div className="content">
              <div className="item-title">
                {t('Global Responsibility:')}
              </div>
              <div className="desc">
                {t('Recognising the global impact of our work and striving to contribute positively to international sustainability efforts, aligning with regulatory standards.')}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
}

export default About;

import './styles/globals.scss';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./layout/Layout";
import Home from './pages/Home';
import About from './pages/About';
import Carbon from './pages/Carbon';
import Careers from './pages/Careers';
import ContactUs from './pages/ContactUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Resources from './pages/Resources';
import Solutions from './pages/Solutions';
import Testimonials from './pages/Testimonials';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="carbon" element={<Carbon />} />
          <Route path="careers" element={<Careers />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="resources" element={<Resources />} />
          <Route path="solutions" element={<Solutions />} />
          <Route path="testimonials" element={<Testimonials />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;

import React from 'react';
import Contact from '../components/contact'
import { useTranslation } from 'react-i18next';

function Testimonials() {
  const { t } = useTranslation();

  return (
    <div className='testimonials'>
      <div className="header-page">
        {t('Our Clients')}
      </div>
      <Contact />

    </div>
  );
}

export default Testimonials;

import React from "react";
import '../styles/home.scss'
import meeting from '../assets/meeting.png'
import Contact from '../components/contact'
import Laptop from '../assets/Laptop.png'
import { useTranslation } from 'react-i18next';

export default function Home() {
  const { t } = useTranslation();

  return (
    <div className="home">
      <div className="header">
        <div className="logo">
          Altedore
        </div>
      </div>
      <div className='container'>
        <div className='content'>
          <p>{t("revolutionising sustainability with ai-powered precision")}</p>
          <p className="mt8">{t("Learn More.")}</p>
        </div>
      </div>
      <img className="bg" src={Laptop} alt="" />
      <div className="container">
        <div>
          <div className='text'>
            <h4>{t("Personalised Solutions For Your Sustainable Future.")}</h4>
            <p>{t("Carbon is at the core of every Net Zero journey.")}</p>
            <p>{t("At Altedore, we combine bespoke consulting with cutting-edge AI technology to master accurate carbon accounting. Our approach is two-fold: we provide custom-crafted strategies through close collaboration with your business, and we enhance these strategies with our advanced, AI-driven tools. This synergy simplifies climate compliance and amplifies the impact of your sustainability practices.")}</p>
            <p>{t("Our comprehensive services is at the forefront of innovations removing the burden of climate compliance reporting whilst providing high quality carbon footprints aligned to the latest methodologies and industry best practice so that you can take these forward into  strategic decision making and focus on transforming your business to one aligned to Net Zero.")}</p>
            <p>{t("Our commitment is steadfast: to empower your business with the innovative tools and expertise needed for a truly sustainable future.")}</p>
            <p>{t("Learn More.")}</p>
            <img src={meeting} alt="meeting" />
          </div>
        </div>
      </div>
      <Contact />

    </div>
  )
}


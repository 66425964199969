import React from 'react';
import Contact from '../components/contact'

function Resources() {
  return (
    <div className='resources'>
      <div className="header-page">
        Resources
      </div>
      <Contact />

    </div>
  );
}

export default Resources;

import useWindowSize from '../hooks/window-size';
import { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/navbar.scss";
import LanguageSwitcher from './LanguageSwitcher'; // Adjust the path as needed
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
function Navbar() {
  const { width } = useWindowSize();
  const [menu, setMenu] = useState(false);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(pathname)
  }, [pathname]);
  return (
    <>
      <nav>
        <div className="container">
          <div className="content">
            <div className="logo">Altedore</div>
            {width >= 991 ? (
              <ul className='links'>
                <li>
                  <Link to="/">{t('Home')}</Link>
                </li>
                <li>
                  <Link to="/about">{t('About')}</Link>
                </li>
                <li>
                  <Link to="/carbon">{t('Carbon')}</Link>
                </li>
                <li>
                  <Link to="/solutions">{t('Solutions')}</Link>
                </li>
                <li>
                  <Link to="/contact-us">{t('Contact Us')}</Link>
                </li>
                <li>
                  <Link to="/careers">{t('Careers')}</Link>
                </li>
                <li>
                  <LanguageSwitcher />
                </li>
              </ul>
            ) : (
              <label className="bars">
                <input
                  type="checkbox"
                  defaultChecked={menu}
                  onChange={() => setMenu(!menu)}
                />
                <span></span>
                <span></span>
                <span></span>
              </label>
            )}
          </div>
        </div>
      </nav>
      <div className={`menu ${menu ? "active" : ""}`}>
        <div className="container">
          <ul className='links'>
            <li>
              <Link to="/">{t('Home')}</Link>
            </li>
            <li>
              <Link to="/about">{t('About')}</Link>
            </li>
            <li>
              <Link to="/carbon">{t('Carbon')}</Link>
            </li>
            <li>
              <Link to="/solutions">{t('Solutions')}</Link>
            </li>
            <li>
              <Link to="/contact-us">{t('Contact Us')}</Link>
            </li>
            <li>
              <Link to="/careers">{t('Careers')}</Link>
            </li>
            <li>
              <LanguageSwitcher />
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Navbar;
import { Link } from "react-router-dom";
import "../styles/footer.scss";
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();

  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-6">
            <div className="title">{t('Quick Links')}</div>
            <ul>
              <li>
                <Link to="/">{t('Home')}</Link>
              </li>
              <li>
                <Link to="/about">{t('About')}</Link>
              </li>
              <li>
                <Link to="/carbon">{t('Carbon')}</Link>
              </li>
              <li>
                <Link to="/solutions">{t('Solutions')}</Link>
              </li>
              <li>
                <Link to="/contact-us">{t('Contact Us')}</Link>
              </li>
              <li>
                <Link to="/careers">{t('Careers')}</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-6">
            <div className="title">{t('Explore')}</div>
            <ul>
              <li><Link to="/careers">{t('Resources')}</Link></li>
              <li><Link to="/careers">{t('Blog')}</Link></li>
              <li><Link to="/testimonials">{t('Testimonials')}</Link></li>
              <li><Link to="/privacy-policy">{t('Privacy Policy')}</Link></li>
            </ul>
          </div>
          <div className="col-md-6 col-12">
            <div className="logo">
              Altedore
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

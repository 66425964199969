// LanguageSwitcher.js
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../styles/navbar.scss";
import useWindowSize from '../hooks/window-size';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const { width } = useWindowSize();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language); // Store the selected language
    setIsOpen(false);

  };
  const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'fr', label: 'Français' },
    { value: 'es', label: 'Español' },
    { value: 'de', label: 'Deutsch' },
  ];
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {width > 991 ? <div className="custom-dropdown">
        <div
          className="selected"
          onClick={() => setIsOpen(!isOpen)}
        >
          <img src={require(`../assets/${languageOptions.find((option) => option.value === i18n.language).value}.png`)} alt={languageOptions.find((option) => option.value === i18n.language).label} />
        </div>
        {isOpen && (
          <ul className="options">
            {languageOptions.filter((option) => option.value !== i18n.language).map((option) => (
              <li
                key={option.value}
                onClick={() => changeLanguage(option.value)}
              >
                <img src={require(`../assets/${option.value}.png`)} alt={option.label} />
              </li>
            ))}
          </ul>
        )}
      </div>
        :
        <ul className="lang-mobile">
          {languageOptions.map((option) => (
            <li
              className={i18n.language === option.value ? 'active' : ''}
              key={option.value}
              onClick={() => changeLanguage(option.value)}
            >
              <img src={require(`../assets/${option.value}.png`)} alt={option.label} />
            </li>
          ))}
        </ul>
      }
    </>

  );
};

export default LanguageSwitcher;

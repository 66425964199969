import React from 'react';
import Contact from '../components/contact'
import { useTranslation } from 'react-i18next';

function Solutions() {
  const { t } = useTranslation();

  return (
    <div className='solutions'>
      <div className="container">
        <div className="header-page">
          {t("Revolutionising Your ESG Journey with Advanced AI Technology")}
        </div>
      </div>
      <div className="bg-text" style={{backgroundImage: `url(${require('../assets/bg.png')})`}}>
        <div className="container">
          <div className="full-width bg-gray text-primary title move-top">
          {t("At Altedore, we offer a holistic suite of solutions designed to seamlessly integrate into your overall ESG framework. Our offerings encompass carbon accounting, CSRD, SEC, ISSB solutions, and materiality assessments, all powered by cutting-edge technology and AI.")}
          </div>
          <div className="right sub-title bg-primary-88 text-white mt12">{t("We cater to a wide range of businesses, with a special focus on medium to large corporates. Our solutions are designed to evolve with your business, and we also offer an adaptable SaaS model tailored for SMEs, ensuring inclusivity and scalability.")}</div>
          <div className="left sub-title bg-green text-white mt12">{t("Our solutions are crafted with excellence in mind, integrating generative AI where it adds the most value. This AI-driven approach bridges the gap between your data and compliance requirements, providing you with insights that are both deep and actionable.")}</div>
          <br />
          <br />
          <br />
          <br />
          <div className="right sub-title bg-gray-70 text-primary mt12">{t("The AI within our solutions adapts to your unique business needs, supporting a range of functions from data analysis to trend predictions and automated compliance reporting. This customization is further enhanced by our professional services, offering a perfect blend of technology and expertise.")}</div>
          <div className="full-width bg-primary-88 text-white sub-title mt12">
          {t("Simplicity, depth, and accessibility are the cornerstones of our user experience. We offer an intuitive interface that makes complex data analysis comprehensible and actionable, ensuring that our solutions are accessible to all levels within your organization.")}
          </div>
          <div className="left sub-title bg-green text-white mt12">{t("At Altedore, we believe in empowering our clients. Alongside our technological solutions, we provide comprehensive support and training, ensuring that you can leverage our tools to their fullest potential.")}</div>
          <div className="right sub-title bg-gray-70 text-primary mt12">{t("Understanding your current sustainability position is the first step towards a greener future. Our solutions not only help in identifying your present ESG standing but also in planning and implementing strategies for improvement, leading to tangible sustainability outcomes.")}</div>
        </div>
      </div>
      <div className="content-info">
        <div className="container">
          <div className="sub-title">
          {t("Our solutions are meticulously aligned with CSRD, SEC, ISSB, and other relevant global standards, ensuring that your business stays ahead in compliance and sustainability practices.")}
          </div>
          <div className="sub-title mt8">
          {t("Embark on your journey towards sustainable business excellence with Altedore. Learn more about how our solutions can transform your ESG endeavors into impactful achievements.")}
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
}

export default Solutions;
